import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  selectAll() {
    this.updateCheckboxes(true);
  }

  selectNone() {
    this.updateCheckboxes(false);
  }

  updateCheckboxes(state) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = state;
    });
  }
}
